@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: 'Lato', sans-serif;
}

h1 {
  font-family: 'Lato', sans-serif;
}

.city {
  color: yellow;
  margin: 10px 0 10px 7px;
}

.back-link {
  display: inline-block;
  padding: 12px 22px;
  color: black;
  background-color: white;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 20px 0 15px 8px;
}

.back-link:hover {
  background-color: rgba(255, 255, 255, 0.7);
  transform: scale(1.05);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.detail {
  display: flex;
  justify-content: space-between;
  margin: 0 0;
  padding: 35px 8px 35px 8px;
  border-radius: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: white;
}

.detail:nth-child(odd) {
  background-color: rgba(200, 40, 110, 1);
}

.detail:nth-child(even) {
  background-color: rgba(236, 76, 138, 1);
}

@media screen and (max-width: 600px) {
  .detail {
    flex-direction: row;
  }
}

.banner2 {
  background-image: url('banner.svg');
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.banner2::after {
  content: '';
  background: rgba(236, 76, 138, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.info {
  padding: 20px;
  background-color: rgba(236, 76, 138, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
}

.info h2 {
  color: yellow;
}

.info p {
  color: white;
  line-height: 1.6;
}

/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
  .detail {
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 9px 40px 9px;
    font-size: 1.4em;
  }

  .back-link {
    font-size: 1.1em;
    padding: 15px 25px;
    margin: 0 0 15px 8px;
  }

  .banner2 {
    height: 600px;
  }
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  .detail {
    justify-content: space-between;
    padding: 40px 9px 40px 9px;
    font-size: 1.5em;
  }

  .info {
    padding: 25px;
  }

  .back-link {
    font-size: 1.2em;
    padding: 15px 30px;
  }

  .city {
    font-size: 1.8em;
  }

  .banner2 {
    height: 600px;
  }
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media screen and (min-width: 1400px) {
  .detail {
    font-size: 1.8em;
    padding: 60px 9px 60px 9px;
  }

  .info {
    padding: 35px;
    font-size: 1.3em;
  }

  .city {
    font-size: 2.5em;
    margin: 30px 0 30px 7px;
  }

  .back-link {
    font-size: 1.3em;
    padding: 20px 40px;
    margin-top: 40px;
  }

  .banner2 {
    height: 600px;
  }

  h2 {
    font-size: 3em;
  }
}
