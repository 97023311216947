@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: 'Monserrat', sans-serif;
  background-color: rgba(200, 40, 110, 1);
}

h1 {
  font-family: 'Lato', sans-serif;
}

.button {
  background-color: rgba(236, 76, 138, 1);
  color: white;
  border-radius: 0;
  height: 100%;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  transition-duration: 0.4s;
  position: relative;
  border: none;
  width: 100%;
  max-height: 280px;
}

.button::before {
  content: '➡';
  position: absolute;
  top: 10%;
  right: 20px;
  color: white;
  transform: translateY(-50%);
}

.button:hover {
  background-color: rgb(255, 212, 212);
  color: rgba(236, 76, 138, 1);
  transition: all 0.3s ease;
}

.container > *:nth-child(4n-1) .button,
.container > *:nth-child(4n-2) .button {
  background-color: rgba(200, 40, 110, 1);
}

.container > *:nth-child(4n-1) .button:hover,
.container > *:nth-child(4n-2) .button:hover {
  background-color: rgb(255, 212, 212);
  color: rgba(236, 76, 138, 1);
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(180px, auto);
  height: 100vh;
}

.banner {
  background-image: url('banner.svg');
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.banner::after {
  content: '';
  background: rgba(236, 76, 138, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.banner-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(235, 255, 13);
  font-size: 3em;
  text-align: center;
  font-family: 'Lato', sans-serif;
  width: 80%;
  height: 50%;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

@media screen and (max-width: 600px) {
  .banner-title {
    font-size: 2em;
  }

  .button {
    width: 100%;
    max-height: 220px;
  }
}

@media screen and (min-width: 768px) {
  .banner-title {
    font-size: 2.5em;
  }

  .container {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .banner {
    background-image: url('banner.svg');
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .container > *:nth-child(4n-1) .button,
  .container > *:nth-child(4n-2) .button {
    background-color: rgba(236, 76, 138, 1);
  }
}

@media screen and (min-width: 1024px) {
  .banner-title {
    font-size: 3em;
  }

  .container {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .button {
    font-size: 18px;
    padding: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .banner-title {
    font-size: 3.5em;
  }

  .container {
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }

  .banner {
    background-image: url('banner.svg');
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .button {
    font-size: 20px;
    padding: 20px;
  }
}
